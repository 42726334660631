import React, { useState } from 'react';

export default function Collapsible({ open, children, title }) {
  const [isOpen, setIsOpen] = useState(open);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <header className={isOpen ? 'active' : ''}>
        <h4>{title}</h4>
        <button type="button" className="btn" onClick={handleFilterOpening}>
          {!isOpen ? '+' : '-'}
        </button>
      </header>
      {isOpen && (
        <div>
          <p>{children}</p>
        </div>
      )}
    </>
  );
}
