import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import FaqList from '../components/FaqList';
import Layout from '../components/Layout';
import SubPageHeader from '../components/SubPageHeader';

const FaqBoxStyles = styled.div`
  margin-bottom: 50px;
  :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
`;

export default function FaqPage({ data }) {
  const faqGroups = data.faq.group;
  return (
    <Layout>
      <SubPageHeader title="Informations" />
      <div>
        {faqGroups.map((item) => (
          <FaqBoxStyles key={item.fieldValue}>
            <h3>{item.fieldValue}</h3>
            <FaqList faq={item.nodes} />
          </FaqBoxStyles>
        ))}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    faq: allSanityFaq {
      group(field: category___name) {
        fieldValue
        nodes {
          id
          answer
          question
        }
      }
    }
  }
`;
