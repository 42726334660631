import React from 'react';
import styled from 'styled-components';
import Collapsible from './Collapsible';

const FaqListStyles = styled.ul`
  margin-top: 30px;
  header {
    position: relative;
    padding: 30px 80px 30px 40px;
    background: var(--grey);
    border: 1px solid var(--black);
    &.active {
      background: var(--black);
      h4,
      button {
        color: var(--white);
      }
    }
    button {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      padding-right: 30px;
      font-family: 'RobotoSlab';
      font-size: 40px;
      color: var(--black);
      text-align: right;
      z-index: 1;
    }
  }
  div {
    padding: 30px 40px;
    border: 1px solid var(--black);
    border-top: 0;
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
  li {
    margin-bottom: 10px;
    :last-child {
      margin-bottom: 0;
    }
  }
  @media (max-width: 767px) {
    margin-top: 20px;
    header {
      padding: 20px 60px 20px 20px;
      button {
        padding-right: 20px;
        font-size: 32px;
      }
    }
    div {
      padding: 20px;
      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`;

export default function FaqList({ faq }) {
  return (
    <FaqListStyles>
      {faq.map((item) => (
        <li key={item.id}>
          <Collapsible title={item.question}>{item.answer}</Collapsible>
        </li>
      ))}
    </FaqListStyles>
  );
}
